
import { defineComponent, onMounted, ref } from 'vue';
import { mapState, useStore } from 'vuex';
import { Picklist } from '../../models/picklist.model';
import { document, close, play } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { JnService } from '../../services/jn.service';
import { EsService } from '../../services/es.service';
import { Utils } from '../../services/utils';
import moment from 'moment';
import {
  MONTH_SHORT_NAMES,
  MONTH_NAMES,
  DAY_SHORT_NAMES,
  DAY_NAMES,
} from '../../models/enums.model';

import {
  SearchTypes,
  QueryTypes,
  OrderingTypes,
  Categories,
} from '../../models/enums.model';

addIcons({
  document: document,
  close: close,
  play: play,
});

import {
  IonContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonItemDivider,
  IonIcon,
  IonInput,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  modalController,
  IonDatetime,
  IonFab,
  IonFabButton,
} from '@ionic/vue';
import { SearchData } from '@/models/search.model';

export default defineComponent({
  name: 'JnBancaDatiSearchParams',
  components: {
    IonContent,
    IonItem,
    IonRadio,
    IonRadioGroup,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    // IonItemDivider,
    IonIcon,
    IonInput,
    IonToolbar,
    IonHeader,
    IonPage,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonDatetime,
    IonFab,
    IonFabButton,
  },

  setup(props) {
    const store = useStore();

    const optCategorie = ref([] as Array<string>);
    const optSubCategorie = ref([] as Array<string>);
    const optMaterie = ref([] as Array<string>);
    const optText = ref('');
    const optType = ref(QueryTypes.OR);
    const optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;

    const tipiQuery = ref([] as Array<Picklist>);
    const tipiOrdinamento = ref([] as Array<Picklist>);

    const optDataDa = ref();
    const optDataA = ref();
    const optAnno = ref();
    const optNumero = ref();

    const materie = ref([] as Array<Picklist>);
    const categorie = ref([] as Array<Picklist>);
    const subCategorie = ref([] as Array<Picklist>);
    let categorieRaw!: any;
    const anni = ref([] as Array<Picklist>);

    const searchData = ref(
      new SearchData({
        index: process.env.VUE_APP_JURANET_INDEXES,
        from: 0,
        size: 10,
        orderingType: OrderingTypes.DATA_CREAZIONE,
        searchType: SearchTypes.BANCA_DATI,
        searchDescription: 'Banca Dati',
        searchInMassime: false
      })
    );

    onMounted(async () => {
      tipiQuery.value = JnService.getQueryTypes();
      tipiOrdinamento.value = JnService.getTipiOrdinamento();

      materie.value = await EsService.getPicklist('materie-banca-dati-juranet');
      materie.value.sort(Utils.sort('label', 'asc'));

      categorieRaw = JSON.parse(
        await EsService.getOptions('categorie-banca-dati-juranet')
      );

      for (const c of categorieRaw) {
        if (!c.id.includes('|')) {
          categorie.value.push(new Picklist({ value: c.id, label: c.value }));
        }
      }
      anni.value = JnService.getAnni();
    });

    const search = async () => {
      searchData.value.keywords = optText.value;
      searchData.value.queryType = optType.value;
      if (optTipoOrdinamento) {
        searchData.value.orderingType = optTipoOrdinamento;
      }

      const idMaterie: string[] = [];
      for (const m of optMaterie.value) {
        idMaterie.push(m);
      }
      searchData.value.idMateria = idMaterie;

      const idCategorie: string[] = [];
      for (const c of optSubCategorie.value) {
        idCategorie.push(c.split('|')[1]);
      }
      searchData.value.idCategory = idCategorie;

      if (optAnno.value) {
        searchData.value.year = optAnno.value;
      }
      if (optNumero.value) {
        searchData.value.number = optNumero.value;
      }

      if (optDataDa.value) {
        searchData.value.since = optDataDa.value;
      }
      if (optDataA.value) {
        searchData.value.to = optDataA.value;
      }
      modalController.dismiss(searchData);
    };

    const close = async () => {
      modalController.dismiss(undefined);
    };
    const clearDataDa = async () => {
      optDataDa.value = '';
    };
    const clearDataA = async () => {
      optDataA.value = '';
    };

    const changeCategoria = async () => {
      optSubCategorie.value = [];
      subCategorie.value = [];
      for (const selectedCategoria of optCategorie.value) {
        for (const sc of categorieRaw) {
          if (sc.id.includes(selectedCategoria + '|')) {
            optSubCategorie.value.push(sc.id);
            subCategorie.value.push(
              new Picklist({ value: sc.id, label: sc.value })
            );
          }
        }
      }
    };

    return {
      tipiQuery,
      optCategorie,
      optSubCategorie,
      optMaterie,
      optText,
      optType,
      optAnno,
      optNumero,
      optTipoOrdinamento,
      tipiOrdinamento,
      categorie,
      anni,
      changeCategoria,
      subCategorie,
      materie,
      search,
      close,
      optDataDa,
      optDataA,
      MONTH_SHORT_NAMES,
      MONTH_NAMES,
      DAY_SHORT_NAMES,
      DAY_NAMES,
      clearDataDa,
      clearDataA,
    };
  },
});
